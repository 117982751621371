import { gql } from '@/shared/graphql/generated/reporting';

export const getTotalSwapVolumeQuery = gql(/* GraphQL */ `
  query GetTotalSwapVolume(
    $startDate: Datetime
    $endDate: Datetime
    $assetFilter: ChainflipAssetFilter
    $isDca: Boolean
    $isBoosted: Boolean
  ) {
    oneLegSwaps: allSwapRequests(
      condition: { isInternal: false }
      filter: {
        isInternal: { equalTo: false }
        executedChunks: { greaterThan: 0 }
        completedBlockTimestamp: { greaterThanOrEqualTo: $startDate, lessThanOrEqualTo: $endDate }
        baseAssetLeg2: { isNull: true }
        isDca: { equalTo: $isDca }
        isBoosted: { equalTo: $isBoosted }
        or: [{ sourceAsset: $assetFilter }, { destAsset: $assetFilter }]
      }
    ) {
      agg: aggregates {
        sum {
          outputValueUsd
          networkFeeValueUsd
        }
      }
      totalCount
    }
    twoLegSwaps: allSwapRequests(
      condition: { isInternal: false }
      filter: {
        isInternal: { equalTo: false }
        executedChunks: { greaterThan: 0 }
        completedBlockTimestamp: { greaterThanOrEqualTo: $startDate, lessThanOrEqualTo: $endDate }
        baseAssetLeg2: { isNull: false }
        isDca: { equalTo: $isDca }
        isBoosted: { equalTo: $isBoosted }
        or: [{ sourceAsset: $assetFilter }, { destAsset: $assetFilter }]
      }
    ) {
      ...SwapRequestGroupedBySideAggregation
    }
    oneLegBoostedSwaps: allSwapRequests(
      condition: { isInternal: false, isBoosted: true }
      filter: {
        executedChunks: { greaterThan: 0 }
        completedBlockTimestamp: { greaterThanOrEqualTo: $startDate, lessThanOrEqualTo: $endDate }
        baseAssetLeg2: { isNull: true }
        isDca: { equalTo: $isDca }
        or: [{ sourceAsset: $assetFilter }, { destAsset: $assetFilter }]
      }
    ) {
      agg: aggregates {
        sum {
          outputValueUsd
          networkFeeValueUsd
        }
      }
      totalCount
    }
    twoLegBoostedSwaps: allSwapRequests(
      condition: { isInternal: false, isBoosted: true }
      filter: {
        executedChunks: { greaterThan: 0 }
        completedBlockTimestamp: { greaterThanOrEqualTo: $startDate, lessThanOrEqualTo: $endDate }
        baseAssetLeg2: { isNull: false }
        isDca: { equalTo: $isDca }
        or: [{ sourceAsset: $assetFilter }, { destAsset: $assetFilter }]
      }
    ) {
      ...SwapRequestGroupedBySideAggregation
    }
    oneLegDcaSwaps: allSwapRequests(
      condition: { isInternal: false, isDca: true }
      filter: {
        executedChunks: { greaterThan: 0 }
        completedBlockTimestamp: { greaterThanOrEqualTo: $startDate, lessThanOrEqualTo: $endDate }
        baseAssetLeg2: { isNull: true }
        isBoosted: { equalTo: $isBoosted }
        or: [{ sourceAsset: $assetFilter }, { destAsset: $assetFilter }]
      }
    ) {
      agg: aggregates {
        sum {
          outputValueUsd
          networkFeeValueUsd
        }
      }
      totalCount
    }
    twoLegDcaSwaps: allSwapRequests(
      condition: { isInternal: false, isDca: true }
      filter: {
        executedChunks: { greaterThan: 0 }
        completedBlockTimestamp: { greaterThanOrEqualTo: $startDate, lessThanOrEqualTo: $endDate }
        baseAssetLeg2: { isNull: false }
        isBoosted: { equalTo: $isBoosted }
        or: [{ sourceAsset: $assetFilter }, { destAsset: $assetFilter }]
      }
    ) {
      ...SwapRequestGroupedBySideAggregation
    }
    boostNetworkFeeSwaps: allSwapRequests(
      condition: { isInternal: true }
      filter: {
        completedBlockTimestamp: { greaterThanOrEqualTo: $startDate, lessThanOrEqualTo: $endDate }
        sourceAsset: { equalTo: Btc }
        destAsset: { equalTo: Flip }
      }
    ) {
      agg: aggregates {
        sum {
          outputValueUsd
        }
      }
    }
  }

  fragment SwapRequestGroupedBySideAggregation on SwapRequestsConnection {
    aggregatesBySourceAsset: groupedAggregates(groupBy: [SOURCE_ASSET]) {
      assetAndDate: keys
      sum {
        intermediateValueUsd
      }
    }
    aggregatesByDestAsset: groupedAggregates(groupBy: [DEST_ASSET]) {
      assetAndDate: keys
      sum {
        outputValueUsd
      }
    }
    agg: aggregates {
      sum {
        networkFeeValueUsd
      }
    }
    totalCount
  }
`);

export const getSwapAggregates = gql(/* GraphQL */ `
  query GetSwapAggregates(
    $minSwapExecutedTimestamp: Datetime! = "2023-01-01T00:00:00.000Z"
    $maxSwapExecutedTimestamp: Datetime
    $assetFilter: ChainflipAssetFilter
    $isBoosted: Boolean
    $isDca: Boolean
  ) {
    oneLegSwaps: allSwapRequests(
      condition: { isInternal: false }
      filter: {
        executedChunks: { greaterThan: 0 }
        completedBlockTimestamp: {
          greaterThanOrEqualTo: $minSwapExecutedTimestamp
          lessThanOrEqualTo: $maxSwapExecutedTimestamp
        }
        baseAssetLeg2: { isNull: true }
        isDca: { equalTo: $isDca }
        isBoosted: { equalTo: $isBoosted }
        or: [{ sourceAsset: $assetFilter }, { destAsset: $assetFilter }]
      }
    ) {
      aggregatesBySourceAsset: groupedAggregates(groupBy: [SOURCE_ASSET, COMPLETED_BLOCK_DATE]) {
        ...SwapRequestAggregatesFragment
      }
      aggregatesByDestAsset: groupedAggregates(groupBy: [DEST_ASSET, COMPLETED_BLOCK_DATE]) {
        ...SwapRequestAggregatesFragment
      }
    }

    twoLegSwaps: allSwapRequests(
      condition: { isInternal: false }
      filter: {
        executedChunks: { greaterThan: 0 }
        completedBlockTimestamp: {
          greaterThanOrEqualTo: $minSwapExecutedTimestamp
          lessThanOrEqualTo: $maxSwapExecutedTimestamp
        }
        baseAssetLeg2: { isNull: false }
        isDca: { equalTo: $isDca }
        isBoosted: { equalTo: $isBoosted }
        or: [{ sourceAsset: $assetFilter }, { destAsset: $assetFilter }]
      }
    ) {
      aggregatesBySourceAsset: groupedAggregates(groupBy: [SOURCE_ASSET, COMPLETED_BLOCK_DATE]) {
        ...SwapRequestAggregatesFragment
      }
      aggregatesByDestAsset: groupedAggregates(groupBy: [DEST_ASSET, COMPLETED_BLOCK_DATE]) {
        ...SwapRequestAggregatesFragment
      }
    }
  }

  fragment SwapRequestAggregatesFragment on SwapRequestAggregates {
    assetAndDate: keys
    distinctCount {
      id
    }
    sum {
      outputValueUsd
      intermediateValueUsd
    }
  }
`);

export const getTopStatsQuery = gql(/* GraphQL */ `
  query getTopStats($startDate: Datetime, $endDate: Datetime) {
    routes: allSwapRequests(
      condition: { isInternal: false }
      filter: {
        executedChunks: { greaterThan: 0 }
        completedBlockTimestamp: { greaterThanOrEqualTo: $startDate, lessThanOrEqualTo: $endDate }
      }
    ) {
      agg: groupedAggregates(groupBy: [SOURCE_ASSET, DEST_ASSET]) {
        sourceAndDestAssets: keys
        sum {
          outputValueUsd
          intermediateValueUsd
        }
        distinctCount {
          swapRequestNativeId
        }
      }
    }
    sources: allSwapRequests(
      condition: { isInternal: false }
      filter: {
        executedChunks: { greaterThan: 0 }
        completedBlockTimestamp: { greaterThanOrEqualTo: $startDate, lessThanOrEqualTo: $endDate }
      }
    ) {
      agg: groupedAggregates(groupBy: SOURCE_ASSET) {
        sum {
          inputValueUsd
        }
        sourceAssets: keys
        distinctCount {
          swapRequestNativeId
        }
      }
    }
    sourcesBoostVolume: allSwapRequests(
      condition: { isInternal: false, isBoosted: true }
      filter: {
        executedChunks: { greaterThan: 0 }
        completedBlockTimestamp: { greaterThanOrEqualTo: $startDate, lessThanOrEqualTo: $endDate }
      }
    ) {
      agg: groupedAggregates(groupBy: SOURCE_ASSET) {
        sum {
          inputValueUsd
        }
        sourceAssets: keys
      }
    }
    dests: allSwapRequests(
      condition: { isInternal: false }
      filter: {
        executedChunks: { greaterThan: 0 }
        completedBlockTimestamp: { greaterThanOrEqualTo: $startDate, lessThanOrEqualTo: $endDate }
      }
    ) {
      agg: groupedAggregates(groupBy: DEST_ASSET) {
        sum {
          egressValueUsd
        }
        destAssets: keys
        distinctCount {
          swapRequestNativeId
        }
      }
    }
  }
`);

export const getAllSwapsQuery = gql(/* GraphQL */ `
  query GetAllSwaps(
    $first: Int
    $offset: Int
    $destinationOrRefundAddress: String
    $swapRequestNativeId: BigInt
    $mainBrokerAccountSs58Id: String
    $asset: ChainflipAsset
  ) {
    allSwapRequests(
      orderBy: [IS_IN_PROGRESS_DESC, SWAP_REQUEST_NATIVE_ID_DESC]
      offset: $offset
      first: $first
      filter: {
        or: [
          { destinationAddress: { includesInsensitive: $destinationOrRefundAddress } }
          { refundAddress: { includesInsensitive: $destinationOrRefundAddress } }
          { swapRequestNativeId: { equalTo: $swapRequestNativeId } }
          { mainBrokerAccountSs58Id: { equalTo: $mainBrokerAccountSs58Id } }
          { sourceAsset: { equalTo: $asset } }
          { destAsset: { equalTo: $asset } }
        ]
        isInternal: { equalTo: false }
      }
    ) {
      pageInfo {
        hasPreviousPage
        startCursor
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...SwapRequestOverview
        }
      }
      totalCount
    }
  }

  fragment SwapRequestOverview on SwapRequest {
    id
    swapRequestNativeId
    sourceAsset
    destAsset
    baseAssetLeg1
    baseAssetLeg2
    ingressAmount
    ingressValueUsd
    egressAmount
    egressValueUsd
    inputAmount
    inputValueUsd
    intermediateAmount
    intermediateValueUsd
    outputAmount
    outputValueUsd
    refundAmount
    refundValueUsd
    networkFeeValueUsd
    totalChunks
    executedChunks
    isDca
    isBoosted
    isInternal
    isCcm
    isVaultSwap
    completedBlockId
    completedBlockTimestamp
    completedBlockDate
    mainBrokerAccountSs58Id
    mainBrokerFeeValueUsd
    affiliateBroker1AccountSs58Id
    affiliateBroker1FeeValueUsd
    affiliateBroker2AccountSs58Id
    affiliateBroker2FeeValueUsd
    affiliateBroker3AccountSs58Id
    affiliateBroker3FeeValueUsd
    affiliateBroker4AccountSs58Id
    affiliateBroker4FeeValueUsd
    affiliateBroker5AccountSs58Id
    affiliateBroker5FeeValueUsd
    completedInSeconds
    startedBlockDate
    startedBlockId
    startedBlockTimestamp
    destinationAddress
    outputAndIntermediateValueUsd
    refundAddress
    status
    isInProgress
  }
`);
