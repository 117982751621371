import React from 'react';
import { formatUsdValue } from '@chainflip/utils/number';
import classNames from 'classnames';
import { TokenWithChainByAsset } from '@/shared/components/logo/TokenWithChain';
import { type ChainflipAsset } from '@/shared/graphql/generated/explorer/graphql';
import { type SwapRequestOverviewFragment } from '@/shared/graphql/generated/reporting/graphql';
import { ArrowIcon, RefundIcon } from '@/shared/icons/large';
import { chainflipAssetMap, TokenAmount } from '@/shared/utils';

type SwapStep = { asset: ChainflipAsset; amount?: string; amountUsd?: string };

const getSwapSteps = (
  swapRequest: SwapRequestOverviewFragment,
  sourceAsset: ChainflipAsset,
  destinationAsset: ChainflipAsset,
  showIntermediate = true,
): [SwapStep, SwapStep] | [SwapStep, SwapStep, SwapStep] => {
  const sourceAmount = TokenAmount.fromAsset(swapRequest.ingressAmount, sourceAsset);
  const hasSourceAmount = (sourceAmount && !sourceAmount.eq(0)) || undefined;
  const sourceAmounts = {
    asset: sourceAsset,
    amount: hasSourceAmount && sourceAmount?.toFormatted(),
    amountUsd: hasSourceAmount && (swapRequest.ingressValueUsd ?? undefined),
  };

  const swapOutputAmount = TokenAmount.fromAsset(swapRequest.outputAmount, destinationAsset);
  const hasSwapOutputAmount = (swapOutputAmount && !swapOutputAmount.eq(0)) || undefined;
  const swapOutputAmounts = {
    asset: destinationAsset,
    amount: hasSwapOutputAmount && swapOutputAmount?.toFormatted(),
    amountUsd: hasSwapOutputAmount && (swapRequest.outputValueUsd ?? undefined),
  };

  const egressAmount = TokenAmount.fromAsset(swapRequest.egressAmount, destinationAsset);
  const hasEgressAmount = (egressAmount && !egressAmount.eq(0)) || undefined;
  const egressAmounts = {
    asset: destinationAsset,
    amount: hasEgressAmount && egressAmount?.toFormatted(),
    amountUsd: hasEgressAmount && (swapRequest.egressValueUsd ?? undefined),
  };

  const intermediateAmount = TokenAmount.fromAsset(swapRequest.intermediateAmount, 'Usdc');
  const hasIntermediateAmount = (intermediateAmount && !intermediateAmount.eq(0)) || undefined;
  const intermediateAmounts = {
    asset: 'Usdc' as ChainflipAsset,
    amount: hasIntermediateAmount && intermediateAmount?.toFormatted('numeral', 2),
    amountUsd: hasIntermediateAmount && (swapRequest.intermediateValueUsd ?? undefined),
  };

  const destinationAmounts = hasEgressAmount ? egressAmounts : swapOutputAmounts;
  if (sourceAsset === 'Usdc' || destinationAsset === 'Usdc') {
    return [sourceAmounts, destinationAmounts];
  }

  if (showIntermediate) {
    return [sourceAmounts, intermediateAmounts, destinationAmounts];
  }
  return [sourceAmounts, destinationAmounts];
};

const StepTokenAmount = ({
  asset,
  amount,
  usdAmount,
  displayChainLogo = true,
  disabled = false,
}: {
  asset: ChainflipAsset;
  amount?: string;
  usdAmount?: string | number;
  displayChainLogo?: boolean;
  disabled?: boolean;
}) => (
  <div className={classNames(disabled && 'opacity-30', 'flex w-[138px] flex-col gap-y-0.5')}>
    <div className="flex items-center gap-x-1">
      <TokenWithChainByAsset asset={asset} size="small" displayChainLogo={displayChainLogo} />
      {amount && (
        <span className="font-aeonikMono text-12 text-white">
          {amount} {chainflipAssetMap[asset].symbol}
        </span>
      )}
    </div>

    {usdAmount !== undefined && (
      <span className="font-aeonikMono text-[11px] text-cf-light-2 lg:text-12">
        {formatUsdValue(usdAmount)}
      </span>
    )}
  </div>
);

const getRoute = (
  steps: [SwapStep, SwapStep] | [SwapStep, SwapStep, SwapStep],
  status: SwapRequestOverviewFragment['status'],
  swapRequest?: SwapRequestOverviewFragment,
  highlightAssets?: ChainflipAsset[],
) =>
  status === 'REFUND_SUCCESS' && swapRequest ? (
    <div className="flex items-center gap-x-4 px-0.5 py-px">
      <StepTokenAmount
        asset={swapRequest.sourceAsset}
        amount={TokenAmount.fromAsset(
          swapRequest.ingressAmount,
          swapRequest.sourceAsset,
        )?.toFormatted()}
        usdAmount={swapRequest.ingressValueUsd ?? undefined}
        displayChainLogo
        disabled={highlightAssets && !highlightAssets.includes(swapRequest.sourceAsset)}
      />
      <RefundIcon width={16} className="block text-cf-light-2" />
      <StepTokenAmount
        asset={swapRequest.sourceAsset}
        amount={TokenAmount.fromAsset(
          swapRequest.refundAmount,
          swapRequest.sourceAsset,
        )?.toFormatted()}
        usdAmount={swapRequest.refundValueUsd ?? undefined}
        displayChainLogo
        disabled={highlightAssets && !highlightAssets.includes(swapRequest.sourceAsset)}
      />
    </div>
  ) : (
    <div className="flex items-center gap-x-4 px-0.5 py-px">
      {steps.map((step, index) => (
        <React.Fragment key={step.asset}>
          <StepTokenAmount
            asset={step.asset}
            amount={step.amount}
            usdAmount={step.amountUsd}
            displayChainLogo={!(index === 1 && steps.length > 2)}
            disabled={highlightAssets && !highlightAssets.includes(step.asset)}
          />
          <ArrowIcon
            width={16}
            className={classNames(
              'text-cf-light-2',
              index + 1 === steps.length ? 'hidden' : 'block',
            )}
          />
        </React.Fragment>
      ))}
    </div>
  );

export const CompactSwapRoute = ({
  routeInfo: { sourceAsset, destinationAsset },
  swapRequest,
  status,
  highlightAssets,
  showIntermediate = true,
}: {
  routeInfo: {
    sourceAsset: ChainflipAsset;
    destinationAsset: ChainflipAsset;
  };
  swapRequest: SwapRequestOverviewFragment;
  status: SwapRequestOverviewFragment['status'];
  highlightAssets?: ChainflipAsset[];
  showIntermediate?: boolean;
}) => {
  if (!sourceAsset || !destinationAsset) return null;
  const steps = getSwapSteps(swapRequest, sourceAsset, destinationAsset, showIntermediate);

  const route = getRoute(steps, status, swapRequest, highlightAssets);

  return route;
};
